const theme = {
  colors: {
    light: "#ffffff",
    dark: "#212121",
    green: "#46D689",
    brownGrey: "#939393",
    "gradient-green":
      "linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);",
    "gradient-dark":
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));",
    "gradient-light":
      "linear-gradient(to bottom, #ededed, rgba(255, 255, 255, 0));",
  },
  lineHeights: { heading: "1.13", body: "1.75", big: "2" },
  fontSizes: {
    heading1: "6.4rem",
    "heading1-mobile": "4rem",
    heading2: "4rem",
    "heading2-mobile": "2.8rem",
    heading3: "2.4rem",
    "heading3-mobile": "2rem",
    body: "1.6rem",
    body2: "1.4rem",
    caption: "1.2rem",
  },
  letterSpacings: {
    heading1: "-1.5px",
    "heading1-mobile": "-0.6px",
    heading2: "-1px",
    "heading2-mobile": "-0.7px",
    heading3: "-0.7px",
    "heading3-mobile": "-0.6px",
    button: "-0.3px",
    menu: "-0.5px",
    "menu-mobile": "-0.6px",
  },
  space: {
    zero: "0",
    xs: "1.6rem",
    s: "2.4rem",
    m: "3.2rem",
    l: "4rem",
    xl: "6.4rem",
    xxl: "12rem",
  },
  layout: {
    width: "73.75rem",
  },
  breakpoints: ["560px", "768px", "1024px", "1440px", "1920rem"],
  sizes: {
    video1: "60%",
    video2: "calc(60% + 200px)",
    footerLogoMobileWidth: "92px",
    footerLogoMobileHeight: "47px",
    footerLogoHeight: "64px",
    footerLogoWidth: "122px",
  },
}

export type Theme = typeof theme

export default theme
