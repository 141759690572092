import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import theme from "../theming/theme"
import GlobalStyle from "../components/global-style.component"
import SiteConfigProvider from "../context/site-config.context"

const Layout: AppFunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SiteConfigProvider>
        <Normalize />
        <GlobalStyle />
        {children}
      </SiteConfigProvider>
    </ThemeProvider>
  )
}

export default Layout
