import { createGlobalStyle } from "styled-components"
import { Theme } from "../theming/theme"

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  html, body  {
    font-family: sofia-pro, sans-serif;
    font-weight: 300;
    line-height: 1.5;
    font-size: 10px;
    white-space: pre-line;
    
     * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  b, strong {
    font-weight: bold;
}
`

export default GlobalStyle
