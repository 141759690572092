import { graphql, useStaticQuery } from "gatsby"
import React, { createContext, FunctionComponent } from "react"
import SiteConfig from "../types/site-config.interface"

export const SiteConfigContext = createContext<SiteConfig>({
  appnrollLink: "",
  twitterLink: "",
  youtubeLink: "",
  linkedinLink: "",
  instagramLink: "",
  facebookLink: "",
  privacyPolicyLink: {
    path: "",
  },
})

const SiteConfigProvider: FunctionComponent = ({ children }) => {
  const { config } = useStaticQuery<{ config: SiteConfig }>(query)
  return (
    <SiteConfigContext.Provider value={config}>
      {children}
    </SiteConfigContext.Provider>
  )
}

export default SiteConfigProvider

const query = graphql`
  query SiteConfigQuery {
    config: contentfulSiteConfiguration {
      appnrollLink
      twitterLink
      facebookLink
      youtubeLink
      linkedinLink
      instagramLink
      privacyPolicyLink {
        path
      }
    }
  }
`
